<template>
  <UserCreate @close="close" />
</template>

<script>
import UserCreate from '@/components/atoms/users/UserCreate.vue'

export default {
  components: {
    UserCreate
  },

  methods: {
    close() {
      this.$router.push('/users')
    }
  }
};
</script>
